
import { defineComponent, onMounted, ref, watch } from 'vue';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import useUtils from '@/mixins/useUtils';

export default defineComponent({
  name: 'QnAListTable',
  props: {
    productDetail: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const qnaList = ref<Array<any>>([]);
    const { maskingId } = useUtils();

    watch(
      () => qnaList.value,
      (newQnaList) => {
        const DEFAULT = 18;
        if (newQnaList && newQnaList.length < DEFAULT) {
          const dummy = [...Array(DEFAULT - newQnaList.length).keys()].map(
            () => {
              return {};
            }
          );
          qnaList.value = qnaList.value.concat(dummy);
        }
      }
    );

    onMounted(() => {
      qnaList.value = props.productDetail.productQnaList
    });

    const formatDate = (date: string) => {
      return date && format(new Date(date), 'yyyy-MM-dd HH:mm');
    };

    return {
      maskingId,
      qnaList,
      formatDate,
    };
  },
});
