<template>
  <div
    v-if="isOpen"
    class="
      fixed
      z-50
      inset-0
      bg-black bg-opacity-40
      flex
      items-center
      justify-center
    "
  >
    <div class="bg-white relative">
      <article class="settlement-modal">
        <header class="modal-header">
          <h1 class="modal__title">
            회원정보
          </h1>
          <button class="modal__close-button" @click="onClose">
            <Close />
          </button>
        </header>
        <div class="modal-content">
          <div class="ProductInfoTable__row with-border">
            <div class="flex">
              <div class="InfoTable__labelColumn">회원명</div>
              <div>{{ member.name }}</div>
            </div>
          </div>
          <div class="ProductInfoTable__row with-border">
            <div class="flex">
              <div class="InfoTable__labelColumn">회원아이디(이메일)</div>
              <div>{{ member.email }}</div>
            </div>
          </div>
          <div class="ProductInfoTable__row with-border">
            <div class="flex">
              <div class="InfoTable__labelColumn">생년월일</div>
              <div>{{ member.birthday }}</div>
            </div>
          </div>
          <div class="ProductInfoTable__row with-border">
            <div class="flex">
              <div class="InfoTable__labelColumn">휴대폰번호</div>
              <div>{{ member.mobile }}</div>
            </div>
          </div>
          <div class="ProductInfoTable__row with-border">
            <div class="flex">
              <div class="InfoTable__labelColumn">기본주소</div>
              <div>{{ member.address }}</div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script lang="ts">
import { watch, defineComponent, ref } from 'vue';
import Close from '@/components/icons/Close.vue';
import partnerAPI from '@/service/partnerAPI';
import convertNumWithComma from '@/utils/convertNumWithComma';
import getServerErrorMessage from "@/utils/getServerErrorMessage";

export default defineComponent({
  name: 'MemberInfoModal.vue',
  props: {
    isOpen: {
      type: Boolean,
    },
    memberId: {
      type: Number,
      required: true
    },
  },
  components: {
    Close,
  },
  setup(props, { emit }) {
    const member = ref({})

    watch(() => props.memberId, (newMemberId, oldMemberId) => {
      console.log(oldMemberId, newMemberId)
      if (newMemberId && (oldMemberId !== newMemberId)) {
        member.value = {}
        fetchMemberInfo(newMemberId)
      }
    })

    const fetchMemberInfo = async (memberId) => {
      try {
        const { data } = await partnerAPI.adminMember.adminMember({
          memberId
        });
        const memberData = (data as any).data.member
        member.value = {
          ...memberData,
          birthday: memberData.birthday?.replace(/^(\d{4})(\d{2})(\d{2})$/, `$1/$2/$3`),
          mobile: memberData.mobile?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
          address: `${memberData.defaultShippingAddress?.address1} ${memberData.defaultShippingAddress?.address2}`
        };
      } catch (e) {
        console.error(e)
        onClose()
        alert(getServerErrorMessage(e));
      }
    };

    const onClose = () => {
      emit('onClose');
    };

    return {
      member,
      convertNumWithComma,
      onClose,
    };
  },
});
</script>

<style scoped lang="scss">
.settlement-modal {
  width: 724px;
  height: 364px;
  .modal {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 21px 20px 40px;
    }

    &__title {
      padding-top: 20px;
      font-size: 20px;
      font-weight: 800;
      line-height: 1.21;
    }

    &-content {
      padding: 0 40px;
    }
  }
}
.confirm-modal-button-area {
  position: absolute;
  bottom: 32px;
  left: 24px;
  width: calc(100% - 48px);
  display: flex;
  align-items: center;

  .confirm-modal__button {
    height: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    width: 60%;
    color: #fff;

    &-cancel {
      color: #202020;
      width: 37%;
      background: #fff;
      border: 1px solid #202020;
      box-sizing: border-box;
      margin-right: 3%;
    }

    &.full {
      width: 100%;
    }
  }
}

.price-area {
  font-size: 16px;
  color: #000000;
  .price-content {
    display: flex;
    align-items: center;
    &.has-border {
      border-bottom: 1px solid;
      padding-bottom: 20px;
    }
    & + .price-content {
      margin-top: 18px;
    }
    &__title {
      display: inline-block;
      min-width: 110px;
      text-align: left;
    }
    &__price {
      display: inline-block;
      min-width: 200px;
      font-weight: normal;
      text-align: right;
    }
    &__unit {
      min-width: 47px;
      display: inline-block;
      text-align: right;
    }
  }
}
.ProductInfoTable__row {
  border-bottom: 1px solid #ddd;
  padding: 0.65rem 0;
  font-size: 14px;
  color: #000;
  &:last-child {
    border-bottom: none;
  }
  &.with-border {
    border-bottom: 1px solid #ddd;
  }
}

.InfoTable__labelColumn {
  color: #999;
  width: 144px;
  flex-shrink: 0;
}
</style>
