
import { watch, defineComponent, ref } from 'vue';
import Close from '@/components/icons/Close.vue';
import partnerAPI from '@/service/partnerAPI';
import convertNumWithComma from '@/utils/convertNumWithComma';
import getServerErrorMessage from "@/utils/getServerErrorMessage";

export default defineComponent({
  name: 'MemberInfoModal.vue',
  props: {
    isOpen: {
      type: Boolean,
    },
    memberId: {
      type: Number,
      required: true
    },
  },
  components: {
    Close,
  },
  setup(props, { emit }) {
    const member = ref({})

    watch(() => props.memberId, (newMemberId, oldMemberId) => {
      console.log(oldMemberId, newMemberId)
      if (newMemberId && (oldMemberId !== newMemberId)) {
        member.value = {}
        fetchMemberInfo(newMemberId)
      }
    })

    const fetchMemberInfo = async (memberId) => {
      try {
        const { data } = await partnerAPI.adminMember.adminMember({
          memberId
        });
        const memberData = (data as any).data.member
        member.value = {
          ...memberData,
          birthday: memberData.birthday?.replace(/^(\d{4})(\d{2})(\d{2})$/, `$1/$2/$3`),
          mobile: memberData.mobile?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
          address: `${memberData.defaultShippingAddress?.address1} ${memberData.defaultShippingAddress?.address2}`
        };
      } catch (e) {
        console.error(e)
        onClose()
        alert(getServerErrorMessage(e));
      }
    };

    const onClose = () => {
      emit('onClose');
    };

    return {
      member,
      convertNumWithComma,
      onClose,
    };
  },
});
