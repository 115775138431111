<template>
  <div class="font-bold text-xl">1:1 문의 현황</div>
  <div class="mt-2">
    <table class="w-full table-fixed">
      <thead class="border">
        <tr class="table w-full text-sm bg-gray-200 bg-gra h-10 text-center">
          <th class="font-normal" style="width: 200px">아이디</th>
          <th class="font-normal" style="width: 180px">문의시간</th>
          <th class="font-normal" style="width: auto">내용</th>
        </tr>
      </thead>

      <tbody
        class="BidTable__body text-left overflow-y-scroll block"
        style="max-height: 480px"
      >
        <tr
          class="table w-full text-sm"
          v-for="(qna, index) in qnaList"
          :key="index"
          :class="index % 2 === 1 && 'bg-gray-200'"
        >
          <td
            style="width: 200px"
            class="BidTable__bodyCell py-2 text-gray-600"
          >
            {{ maskingId(qna.email) || '-' }}
          </td>
          <td
            style="width: 180px"
            class="BidTable__bodyCell py-2 text-gray-600"
          >
            {{ formatDate(qna.createdAt) || '-' }}
          </td>
          <td style="width: auto" class="BidTable__bodyCell py-2">
            {{ qna.memo || '-' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import useUtils from '@/mixins/useUtils';

export default defineComponent({
  name: 'QnAListTable',
  props: {
    productDetail: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const qnaList = ref<Array<any>>([]);
    const { maskingId } = useUtils();

    watch(
      () => qnaList.value,
      (newQnaList) => {
        const DEFAULT = 18;
        if (newQnaList && newQnaList.length < DEFAULT) {
          const dummy = [...Array(DEFAULT - newQnaList.length).keys()].map(
            () => {
              return {};
            }
          );
          qnaList.value = qnaList.value.concat(dummy);
        }
      }
    );

    onMounted(() => {
      qnaList.value = props.productDetail.productQnaList
    });

    const formatDate = (date: string) => {
      return date && format(new Date(date), 'yyyy-MM-dd HH:mm');
    };

    return {
      maskingId,
      qnaList,
      formatDate,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../../styles/mixins.scss';

.BidTable__body {
  @include scrollBar(12px, #babac0, #f3f4f6);
  border-right: 1px solid #babac0;

  tr > td {
    &:last-child {
      border-right: 1px solid #babac0;
    }
  }
}
</style>
