<template>
  <div class="font-bold text-xl">BID 현황</div>
  <div class="mt-2">
    <table class="w-full table-fixed">
      <thead class="border">
        <tr class="table w-full text-sm bg-gray-200 bg-gra h-10 text-center">
          <th class="font-normal" style="width: 180px">아이디</th>
          <th class="font-normal" style="width: 140px">BID Time</th>
          <th class="font-normal" style="width: 180px">BID 금액</th>
          <th class="font-normal" style="width: 50px">유형</th>
          <th class="font-normal">상태</th>
        </tr>
      </thead>

      <tbody
        class="BidTable__body text-left overflow-y-scroll block"
        style="max-height: 480px"
      >
        <tr
          class="table w-full text-sm"
          v-for="(bid, index) in bidList"
          :key="index"
          :class="index % 2 === 1 && 'bg-gray-200'"
          @click="() => {
            targetMemberId = bid.memberId
            isOpenMemberInfo = true
          }"
        >
          <td v-if="(productDetail.winMemberId === bid.memberId && index === 0)" class="py-2 text-gray-600" style="width: 180px">
            {{ bid.email }}
          </td>
          <td v-else class="py-2 text-gray-600" style="width: 180px">
            {{ maskingId(bid.email) || '-' }}
          </td>
          <td class="py-2 text-gray-600" style="width: 140px">
            {{ formatDate(bid.createdAt) || '-' }}
          </td>
          <td class="py-2 font-bold" style="width: 180px; text-align: center;">
            {{ formatNumber(bid.bidPrice) || '-' }}<span v-if="bid.bidPrice" class="font-normal">원</span>
          </td>
          <td style="width: 50px; text-align: center">
            <span v-if="(bid.autoBidding === true)" class="bidding-auto-label">Auto</span>
          </td>
          <td class="py-2" style="text-align: center">
            {{ (productDetail.winMemberId === bid.memberId && index === 0) ? '낙찰' : '-' }}
          </td>
        </tr>
      </tbody>
    </table>
    <member-info-modal
      @onClose="
        () => {
          isOpenMemberInfo = false
        }
      "
      :memberId="targetMemberId"
      :isOpen="isOpenMemberInfo"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import useUtils from '@/mixins/useUtils';
import MemberInfoModal from "@/components/MemberInfoModal.vue";

export default defineComponent({
  name: 'BidListTable',
  props: {
    productDetail: {
      type: Object,
      required: true,
    }
  },
  components: {
    MemberInfoModal
  },
  setup(props) {
    const bidList = ref<Array<any>>([]);
    const { maskingId } = useUtils();
    const isOpenMemberInfo = ref(false)
    const targetMemberId = ref(null)

    const fetchBidList = async () => {
      try {
        const { data } = await partnerAPI.commonLot.bidderListUsingGET({
          productId: props.productDetail.productId,
        });
        console.log(data as any);
        bidList.value = (data as any).data.sort((p, n) =>
          new Date(p.createdAt).getTime() < new Date(n.createdAt).getTime()
            ? 1
            : -1
        );
      } catch (e) {
        console.error(e);
      }
    };


    watch(
      () => bidList.value,
      (newBidList) => {
        const DEFAULT = 18;
        if (newBidList && newBidList.length < DEFAULT) {
          const dummy = [...Array(DEFAULT - newBidList.length).keys()].map(
            () => {
              return {};
            }
          );
          bidList.value = bidList.value.concat(dummy);
        }
      }
    );

    onMounted(() => {
      fetchBidList();
    });

    const formatDate = (date: string) => {
      return date && format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const formatNumber = (number) => {
      return number && number.toLocaleString();
    };

    return {
      maskingId,
      bidList,
      formatDate,
      formatNumber,
      isOpenMemberInfo,
      targetMemberId
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../../styles/mixins.scss';
.BidTable__body {
  @include scrollBar(12px, #babac0, #f3f4f6);
  border-right: 1px solid #babac0;

  tr > td {
    &:last-child {
      border-right: 1px solid #babac0;
    }
  }
}

.bidding-auto-label {
  margin-left: 4px;
  border-radius: 10px;
  padding: 2px 6px;
  color: #FFFFFF;
  background-color: rgba(73, 205, 218, 1);
  font-weight: 400;
  font-size: 12px;
}
</style>
